var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      [
        _c(
          "SfButton",
          {
            staticClass: "sf-search-bar__button sf-button--pure custombtncode",
            attrs: { "aria-label": "Open search" },
            on: { click: _vm.showSearch },
          },
          [
            _c(
              "span",
              { staticClass: "sf-search-bar__icon" },
              [
                _c("SvgImage", {
                  attrs: {
                    icon: "search",
                    label: _vm.$t("Search"),
                    width: "18",
                    height: "18",
                  },
                }),
              ],
              1
            ),
          ]
        ),
      ],
      _vm._v(" "),
      _c("SfSearchBar", {
        directives: [
          {
            name: "click-outside",
            rawName: "v-click-outside",
            value: _vm.closeSearch,
            expression: "closeSearch",
          },
        ],
        staticClass: "sf-header__search",
        attrs: {
          placeholder: _vm.$t("Search for items"),
          "aria-label": "Search",
          value: _vm.term,
        },
        on: {
          input: function ($event) {
            return _vm.debouncedHandleSearch($event)
          },
          keyup: function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            ) {
              return null
            }
            _vm.handleKeydownEnter(
              $event.target.value
            ) /* https://github.com/vuestorefront/storefront-ui/issues/2453#issuecomment-1160231619 */
          },
          keydown: [
            function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "tab", 9, $event.key, "Tab")
              ) {
                return null
              }
              return _vm.hideSearch.apply(null, arguments)
            },
            function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
              ) {
                return null
              }
              return _vm.closeSearch.apply(null, arguments)
            },
          ],
          focus: _vm.showSearch,
          click: _vm.showSearch,
        },
        scopedSlots: _vm._u([
          {
            key: "icon",
            fn: function () {
              return [
                !!_vm.term
                  ? _c(
                      "SfButton",
                      {
                        staticClass: "sf-search-bar__button sf-button--pure",
                        attrs: { "aria-label": "Close search" },
                        on: { click: _vm.closeSearch },
                      },
                      [
                        _c(
                          "span",
                          { staticClass: "sf-search-bar__icon" },
                          [
                            _c("SvgImage", {
                              attrs: {
                                icon: "cross",
                                label: _vm.$t("Cancel"),
                                width: "18",
                                height: "18",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    )
                  : _c(
                      "SfButton",
                      {
                        staticClass: "sf-search-bar__button sf-button--pure",
                        attrs: { "aria-label": "Open search" },
                        on: {
                          click: _vm.toggleSearch,
                          keydown: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "tab",
                                9,
                                $event.key,
                                "Tab"
                              )
                            ) {
                              return null
                            }
                            return _vm.hideSearch.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _c(
                          "span",
                          { staticClass: "sf-search-bar__icon" },
                          [
                            _c("SvgImage", {
                              attrs: {
                                icon: "search",
                                label: _vm.$t("Search"),
                                width: "18",
                                height: "18",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }